export default {
    name: "overlay",
    store() {
        return {
            classes: 'disabled',
            style: '0.5',
            overlay_style: '',
            init() {
                this.updateClasses();
            },
            update(overlayStyle) {
                this.style = overlayStyle;
                this.updateClasses();
                // console.log('Overlay', this.classes);
            },
            updateClasses() {
                // Ensure 'enabled' or 'disabled' are toggled without overwriting overlay_style
                const baseClass = this.classes.includes('enabled') ? 'enabled' : 'disabled';
                this.classes = `${baseClass}`;
            },
            show() {
                this.classes = this.classes.replace('disabled', '').trim();
                this.classes = `enabled`;
            },
            hide() {
                this.classes = this.classes.replace('enabled', '').trim();
                this.classes = `disabled`;
            },
        };
    },
};
