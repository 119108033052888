import shopifyHelper from "../../shopify";

export default {
    name: 'recentlyviewed',
    component() {
        return {
            recentlyviewedproducts: Alpine.$persist([]).as('recentlyviewed_products'),
            init() {
                },
            async store(product_id){
                const transformedProduct = await shopifyHelper.getShopifyProductList([product_id]);
                if (transformedProduct){

                    const isDuplicate = this.recentlyviewedproducts.some(product => parseInt(product.id) === parseInt(transformedProduct[0].id));

                    if (!isDuplicate) {
                        this.recentlyviewedproducts = this.recentlyviewedproducts.concat(transformedProduct);
                        console.log(this.recentlyviewedproducts);
                    } else {
                        // console.log('Product with id ' + product_id + ' already exists.');
                    }

                }
            },
            get() {
                const products = JSON.parse(localStorage.getItem("recentlyviewed_products"));
                let recentlyViewedProducts = [];

                if (products && Array.isArray(products)) {
                    if (products.length > 0 && products[0]?.id) {
                        recentlyViewedProducts = products.map(product => product.id);
                    }
                }

                return recentlyViewedProducts;
            },
        }
    }
}
