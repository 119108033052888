let currentVariant = null;
let selectedBasePrice = 0;

export default {
    name: "productform",
    component() {
        return {
            init() {
            },
            setCurrentVariant(variant) {
                currentVariant = variant;
            },
            getCurrentVariant() {
                return currentVariant;
            },
            getSelectedBasePrice() {
                return selectedBasePrice;
            },
            getProductForms(form){
                let productForms = null;
                if (!form) {
                    productForms = document.querySelectorAll(
                        '.pdp-details .product-form',
                    );
                } else {
                    productForms = [form];
                }

                return productForms;
            },
            hideAddButton(form = null) {
                const productForms = this.getProductForms();
                productForms.forEach((productForm) => {
                    if (!productForm) return;
                    const addButton = productForm.querySelector('[name="add"]');
                    if (!addButton) return;

                    addButton.style.display = 'none';
                });
            },
            hideBackInStockForm(form = null) {
                // LM ONLY
                const addButton = document.querySelector('.pdp-details .outofstock-group');
                if (!addButton) return;

                addButton.style.display = 'none';
            },
            showBackInStockForm(form = null) {
                // LM ONLY
                const addButton = document.querySelector('.pdp-details .outofstock-group');
                if (!addButton) return;

                addButton.style.display = 'block';
            },
            showAddButton(form = null) {
                const productForms = this.getProductForms();
                productForms.forEach((productForm) => {
                    if (!productForm) return;
                    const addButton = productForm.querySelector('[name="add"]');
                    if (!addButton) return;

                    addButton.style.display = 'inline-flex';
                });
            },
            updateAddButtonDom(disable = true, text, modifyClass = true, basePrice = 0, form = null) {
                const productForms = this.getProductForms(form);
                this.showAddButton();
                this.hideBackInStockForm();
                if (basePrice > 0) {
                    selectedBasePrice = basePrice;
                }
                productForms.forEach((productForm) => {
                    if (!productForm) return;
                    const addButton = productForm.querySelector('[name="add"]');
                    const addButtonText = productForm.querySelector(
                        '[name="add"] > span',
                    );
                    if (!addButton) return;
                    if (disable) {
                        addButton.setAttribute("disabled", "disabled");
                        addButton
                            .querySelector("span")
                            .setAttribute(
                                "x-on:click",
                                "$nextTick(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); })",
                            );
                        if (text) addButtonText.textContent = text;
                    } else {
                        addButton.removeAttribute("disabled");
                        if (text) addButtonText.textContent = text;
                    }

                    if (!modifyClass) return;

                    if (disable) {
                        addButton.classList.add("disabled");
                    } else {
                        addButton.classList.remove("disabled");
                    }
                });
            },
        };
    },
};
